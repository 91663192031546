import { useIsMetaKeyPressed } from "@/hooks/useIsMetaKeyPressed";
import { useCreateMerchantNote } from "@/pages/merchants/onboarding/merchantNotes/useCreateMerchantNote";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

type CreateNewMerchantNoteFormType = {
	message: string;
};

export function useCreateNewMerchantNoteForm(setDirty: (dirty: boolean) => void, merchantId?: string) {
	const { createMerchantNote, loading, error } = useCreateMerchantNote(merchantId);
	const form = useForm<CreateNewMerchantNoteFormType>({
		mode: "uncontrolled",
		initialValues: {
			message: "",
		},
		validate: {
			message: isNotEmpty("Please enter a message"),
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
	});
	const onSubmit = async (values: CreateNewMerchantNoteFormType) => {
		if (!merchantId) {
			return;
		}
		try {
			const { data } = await createMerchantNote({
				variables: {
					input: {
						merchantId,
						message: values.message,
					},
				},
			});
			if (data?.merchantNote?.create?.success) {
				notifications.show({
					title: "Merchant note created",
					message: "Merchant note successfully submitted",
				});
				form.reset();
				setDirty(false);
			} else {
				notifications.show({
					title: `Failed to create merchant note: ${data?.merchantNote?.create?.error?.code}`,
					message: data?.merchantNote?.create?.error?.message,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to create merchant note",
				message: "Something went wrong while creating the merchant note.",
			});
		}
	};
	const formSubmit = form.onSubmit(onSubmit);
	const { isMetaKeyPressed, metaKeyDisplay } = useIsMetaKeyPressed();
	const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		const shouldSubmit = isMetaKeyPressed(event) && event.key === "Enter";
		if (shouldSubmit) {
			formSubmit();
		}
	};

	return {
		form,
		onSubmit: formSubmit,
		loading,
		error,
		metaKeyDisplay,
		onKeyDown,
	};
}
