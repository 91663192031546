import { gql } from "@/__generated__";
import type { MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { useMerchantsForMerchantsTable } from "@/pages/merchants/merchantsTable/useMerchantsForMerchantsTable";
import { useMerchantsTableColumns } from "@/pages/merchants/merchantsTable/useMerchantsTableColumns";
import { paths } from "@/paths";
import { Alert, Menu } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_ColumnFiltersState, type MRT_SortingState, MantineReactTable } from "mantine-react-table";
import { useState } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

export const MerchantsTable_Merchant = gql(`
fragment MerchantsTable_Merchant on Merchant {
	id
	createdAt
	updatedAt
	legalEntityName
	stage
	status
	leadData {
		annualRevenue {
			amount
			currency
		}
		industry
		contactName
		contactEmail
		contactPhoneNumber
		contactJobTitle
		estimatedMonthlyPaymentVolume
		paymentMethodsToAccept
		hasNeedForRecurringPayments
		additionalRequirements
	}
	prospectingData {
		testing
	}
	platform {
		id
		name
	}
	createdBy {
		...getDisplayName_User
	}
	lastUpdatedBy {
		...getDisplayName_User
	}
}
`);

export function MerchantsTable() {
	const { columns, initialColumnVisibility } = useMerchantsTableColumns();
	const [globalFilter, setGlobalFilter] = useState("");
	const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
	const [sorting, setSorting] = useState<MRT_SortingState>([]);
	const { merchants, loading, error } = useMerchantsForMerchantsTable({ globalFilter, columnFilters, sorting });
	const generatePathForAssistedOnboarding = (merchant: MerchantsTable_MerchantFragment) =>
		generatePath(paths.assistedOnboarding, { merchantId: merchant.id });
	const table = useFastlaneTable<MerchantsTable_MerchantFragment>({
		data: merchants,
		columns: columns,
		searchPlaceholder: "Search merchants",
		isLoading: loading,
		initialColumnVisibility,
		enableColumnFilters: true,
		manualFiltering: true,
		globalFilter,
		columnFilters,
		manualSorting: true,
		sorting,
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilters,
		onSortingChange: setSorting,
		enableRowActions: true,
		getOnClickNavigationProps: (row) => ({
			path: generatePathForAssistedOnboarding(row.original),
			name: row.original.legalEntityName || "Merchant without name",
		}),
		renderRowActionMenuItems: ({ row }) => (
			<Menu.Item component={Link} to={generatePathForAssistedOnboarding(row.original)}>
				Open merchant
			</Menu.Item>
		),
	});
	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<MantineReactTable table={table} />
		</div>
	);
}
