import { type FragmentType, gql, useFragment } from "@/__generated__";
import {
	BusinessInformation_MerchantFragmentDoc,
	BusinessInformation_QueryFragmentDoc,
	MerchantStage,
	UserType,
} from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { SelectPlaform } from "@/components/SelectPlaform";
import { useBusinessInformationForm } from "@/pages/merchants/onboarding/businessInformation/useBusinessInformationForm";
import { MerchantStatusSelect } from "@/pages/merchants/onboarding/common/MerchantStatusSelector";
import { STATUSES_BY_STAGE } from "@/pages/merchants/onboarding/constants";
import { Button, Flex, Grid, Stack, TextInput, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

gql(`
fragment BusinessInformation_Merchant on Merchant {
	id
	legalEntityName
	stage
	status
	platform {
		id
	}
}
`);

const BusinessInformation_Query = gql(`
fragment BusinessInformation_Query on Query {
	...SelectPlaform_Query
	merchant(merchantId: $merchantId)  @include(if: $merchantIdProvided) {
		...BusinessInformation_Merchant
	}
}
`);

type BusinessInformationProps = {
	onClose: () => void;
	setDirty: (dirty: boolean) => void;
	data?: FragmentType<typeof BusinessInformation_Query>;
};

export function BusinessInformation({ onClose, setDirty, data }: BusinessInformationProps) {
	const queryData = useFragment(BusinessInformation_QueryFragmentDoc, data);
	const merchantData = useFragment(BusinessInformation_MerchantFragmentDoc, queryData?.merchant);
	const { form, submittingContinue, submittingSave, onContinue, onSave } = useBusinessInformationForm(
		setDirty,
		merchantData,
	);
	const { user } = useAuth();
	const isFastlaneUser = user?.type === UserType.FASTLANEIQ;
	return (
		<form style={{ display: "contents" }}>
			<Stack gap="xl" h="100%" justify="space-between">
				<Stack gap="lg">
					<Title order={1}>Business Information</Title>
					{merchantData && (
						<Grid>
							<Grid.Col span={{ xs: 12, sm: 6 }}>
								<MerchantStatusSelect
									selectableStatuses={STATUSES_BY_STAGE[MerchantStage.BUSINESS_INFORMATION]}
									{...form.getInputProps("status")}
									label="Status"
									key={form.key("status")}
									disabled={merchantData?.stage !== MerchantStage.BUSINESS_INFORMATION}
								/>
							</Grid.Col>
						</Grid>
					)}
					<Grid>
						<Grid.Col span={{ xs: 12, sm: 6 }}>
							<TextInput
								{...form.getInputProps("legalEntityName")}
								label="Legal entity name"
								placeholder="Legal entity name"
								withAsterisk
								key={form.key("legalEntityName")}
							/>
						</Grid.Col>
						{isFastlaneUser && (
							<Grid.Col span={{ xs: 12, sm: 6 }}>
								<SelectPlaform
									platformData={queryData}
									{...form.getInputProps("platformId")}
									label="Platform"
									placeholder="Platform"
									key={form.key("platformId")}
								/>
							</Grid.Col>
						)}
					</Grid>
				</Stack>
				<Flex direction="row" justify="end" gap="sm">
					<Button type="button" variant="subtle" color="gray" onClick={onClose}>
						Cancel
					</Button>
					<Button loading={submittingSave} type="submit" onClick={onSave}>
						Save
					</Button>
					<Button
						loading={submittingContinue}
						variant="light"
						type="submit"
						onClick={onContinue}
						disabled={merchantData?.stage !== MerchantStage.BUSINESS_INFORMATION}
						rightSection={<IconArrowRight />}
					>
						Continue
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
