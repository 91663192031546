import { type FragmentType, gql, useFragment } from "@/__generated__";
import { EmptyStage_MerchantFragmentDoc, type MerchantStage } from "@/__generated__/graphql";
import { MerchantStatusSelect } from "@/pages/merchants/onboarding/common/MerchantStatusSelector";
import { STATUSES_BY_STAGE } from "@/pages/merchants/onboarding/constants";
import { useEmptyStepForm } from "@/pages/merchants/onboarding/emptyStep/useEmptyOnboardingStepForm";
import { Button, Flex, Grid, Stack, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const EmptyStage_MerchantFragment = gql(`
fragment EmptyStage_Merchant on Merchant {
	id
	stage
	status
}
`);

type ContractingProps = {
	onClose: () => void;
	setDirty: (dirty: boolean) => void;
	merchant: FragmentType<typeof EmptyStage_MerchantFragment>;
	stage: MerchantStage;
	title: string;
};

export function EmptyOnboardingStep({ setDirty, merchant, onClose, stage, title }: ContractingProps) {
	const merchantData = useFragment(EmptyStage_MerchantFragmentDoc, merchant);
	const { form, onContinue, onSave, submittingContinue, submittingSave } = useEmptyStepForm(setDirty, merchantData);
	return (
		<form style={{ display: "contents" }}>
			<Stack gap="xl" h="100%" justify="space-between">
				<Stack gap="lg">
					<Title order={1}>{title}</Title>
					<Grid>
						<Grid.Col span={{ xs: 12, sm: 6 }}>
							<MerchantStatusSelect
								selectableStatuses={STATUSES_BY_STAGE[stage]}
								{...form.getInputProps("status")}
								label="Status"
								key={form.key("status")}
								disabled={merchantData?.stage !== stage}
							/>
						</Grid.Col>
					</Grid>
				</Stack>
				<Flex direction="row" justify="end" gap="sm">
					<Button type="button" variant="subtle" color="gray" onClick={onClose}>
						Cancel
					</Button>
					<Button loading={submittingSave} type="submit" onClick={onSave}>
						Save
					</Button>
					<Button
						loading={submittingContinue}
						variant="light"
						type="submit"
						onClick={onContinue}
						disabled={merchantData.stage !== stage}
						rightSection={<IconArrowRight />}
					>
						Continue
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
