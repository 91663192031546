import { MerchantStage, MerchantStatus } from "@/__generated__/graphql";

export const STATUSES_BY_STAGE: Record<MerchantStage, MerchantStatus[]> = {
	[MerchantStage.BUSINESS_INFORMATION]: [
		MerchantStatus.LEAD_GENERATED,
		MerchantStatus.INITIAL_CONTACT_MADE,
		MerchantStatus.INFORMATION_REQUESTED,
		MerchantStatus.QUALIFIED_LEAD,
		MerchantStatus.DISQUALIFIED,
	],
	[MerchantStage.TRANSACTION_INFORMATION]: [
		MerchantStatus.ELIGIBILITY_CHECK_INITIATED,
		MerchantStatus.AWAITING_BUSINESS_INFORMATION,
		MerchantStatus.UNDER_REVIEW,
		MerchantStatus.ELIGIBLE,
		MerchantStatus.NOT_ELIGIBLE,
		MerchantStatus.ADDITIONAL_INFORMATION_REQUIRED,
	],
	[MerchantStage.SOLUTION_REQUIREMENTS]: [
		MerchantStatus.KYC_AML_CHECK_INITIATED,
		MerchantStatus.DOCUMENT_COLLECTION_IN_PROGRESS,
		MerchantStatus.AWAITING_ADDITIONAL_DOCUMENTS,
		MerchantStatus.VERIFICATION_PASSED,
		MerchantStatus.VERIFICATION_FAILED,
	],
	[MerchantStage.FEES]: [
		MerchantStatus.ACCOUNT_SETUP_INITIATED,
		MerchantStatus.INTEGRATION_IN_PROGRESS,
		MerchantStatus.TESTING_PHASE,
		MerchantStatus.AWAITING_API_CREDENTIALS,
		MerchantStatus.TRAINING_REQUIRED,
		MerchantStatus.SETUP_COMPLETE,
	],
	[MerchantStage.CONTRACTING]: [
		MerchantStatus.CONTRACT_SENT,
		MerchantStatus.AWAITING_SIGNATURE,
		MerchantStatus.CONTRACT_APPROVED,
		MerchantStatus.CONTRACT_DECLINED,
	],
	[MerchantStage.LIVE]: [
		MerchantStatus.SOFT_LAUNCH,
		MerchantStatus.MONITORING_PERIOD,
		MerchantStatus.FULLY_OPERATIONAL,
		MerchantStatus.UNDER_PERFORMANCE_REVIEW,
		MerchantStatus.TEMPORARILY_SUSPENDED,
	],
	[MerchantStage.CLOSED]: [],
};
