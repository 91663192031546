import { useOs } from "@mantine/hooks";

export function useIsMetaKeyPressed() {
	const os = useOs();
	const isMac = os === "macos" || os === "ios";
	return {
		isMetaKeyPressed: (event: React.KeyboardEvent | React.MouseEvent) => {
			return (isMac && event.metaKey) || (!isMac && event.ctrlKey);
		},
		metaKeyDisplay: isMac ? "⌘" : "Ctrl",
	};
}
