import { gql } from "@/__generated__";
import { addNewMerchantFirstInCache, evictPlatformStageStatistics } from "@/pages/merchants/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_ONBOARDING_DATA_MUTATION = gql(`
mutation saveOnboardingData($input: SaveOnboardingDataInput!) {
	merchant {
		saveOnboardingData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...EmptyStage_Merchant
				...BusinessInformation_Merchant
				...evictPlatformStageStatistics_Merchant
				...addNewMerchantFirstInCache_Merchant
			}
		}
	}
}
`);
export function useSaveOnboardingData(existingMerchantId: string) {
	const [saveOnboardingData, { loading, error }] = useMutation(SAVE_ONBOARDING_DATA_MUTATION, {
		update(cache, { data }) {
			const saveOnboardingData = data?.merchant.saveOnboardingData;
			if (saveOnboardingData?.success && saveOnboardingData?.merchant) {
				evictPlatformStageStatistics(cache, saveOnboardingData.merchant);
			}
			if (!existingMerchantId) {
				// Only need to add the new merchant to the cache if it doesn't exist, updates are handled by apollo
				if (saveOnboardingData?.success && saveOnboardingData?.merchant) {
					addNewMerchantFirstInCache(cache, saveOnboardingData.merchant);
				}
			}
		},
	});
	return {
		saveOnboardingData,
		loading,
		error,
	};
}
