import { gql, useFragment } from "@/__generated__";
import {
	type PlatformsTable_PlatformFragment,
	PlatformsTable_PlatformFragmentDoc,
	PlatformsTable_QueryFragmentDoc,
} from "@/__generated__/graphql";
import { MoneyCell } from "@/components/table/MoneyCell";
import { TimestampCell } from "@/components/table/TimestampCell";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { getDisplayName, snakeToHumanReadable } from "@/pages/common/utils";
import { CopySubmitLeadLinkItem } from "@/pages/platforms/platformsTable/CopySubmitLeadLinkItem";
import { usePlatformsTableData } from "@/pages/platforms/platformsTable/usePlatformsTableData";
import { paths } from "@/paths";
import { Alert, Menu } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link, generatePath } from "react-router-dom";

export const PlatformsTable_Platform = gql(`
fragment PlatformsTable_Platform on Platform {
	id
	createdAt
	updatedAt
	name
	contactName
	contactEmail
	contactPhoneNumber
	createdBy {
		...getDisplayName_User
	}
	lastUpdatedBy {
		...getDisplayName_User
	}
	projectedRevenue {
		amount
		currency
	}
	status
}
`);

gql(`
fragment PlatformsTable_Query on Query {
	platforms {
		edges {
			node {
				...PlatformsTable_Platform
			}
		}
	}
}
`);

export function PlatformsTable() {
	const { data, loading, error } = usePlatformsTableData();
	const platformsConnection = useFragment(PlatformsTable_QueryFragmentDoc, data);
	const platforms =
		platformsConnection?.platforms?.edges.map((edge) => useFragment(PlatformsTable_PlatformFragmentDoc, edge.node)) ||
		[];
	const columns: Array<MRT_ColumnDef<PlatformsTable_PlatformFragment>> = useMemo(
		() => [
			{
				accessorKey: "name",
				header: "Name",
			},
			{
				accessorFn: (p) => snakeToHumanReadable(p.status),
				id: "status",
				header: "Status",
				size: 120,
				grow: false,
			},
			{
				accessorFn: (p) => p.projectedRevenue,
				id: "projectedRevenue",
				header: "Projected revenue",
				Cell: MoneyCell,
				size: 200,
				filterVariant: "range",
				mantineFilterTextInputProps: { type: "number" },
				grow: false,
			},
			{
				accessorKey: "contactName",
				header: "Contact name",
				size: 225,
				grow: false,
			},
			{
				accessorKey: "contactEmail",
				header: "Contact email",
				size: 200,
				grow: false,
			},
			{
				accessorFn: (p) => (p.contactPhoneNumber ? formatPhoneNumberIntl(p.contactPhoneNumber) : ""),
				id: "contactPhoneNumber",
				header: "Contact phone number",
				size: 200,
				grow: false,
			},
			{
				accessorFn: (p) => getDisplayName(p.createdBy),
				header: "Created by",
				id: "createdBy",
				size: 225,
				grow: false,
			},
			{
				header: "Created",
				accessorKey: "createdAt",
				size: 200,
				grow: false,
				Cell: TimestampCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.lastUpdatedBy),
				header: "Last updated by",
				id: "lastUpdatedBy",
				size: 225,
				grow: false,
			},
			{
				header: "Last updated",
				accessorKey: "updatedAt",
				size: 200,
				grow: false,
				Cell: TimestampCell,
			},
		],
		[],
	);

	const generatePathForPlatform = (platform: PlatformsTable_PlatformFragment) =>
		generatePath(paths.platform, { platformId: platform.id });
	const table = useFastlaneTable<PlatformsTable_PlatformFragment>({
		data: platforms,
		columns: columns,
		searchPlaceholder: "Search platforms",
		isLoading: loading,
		enableRowActions: true,
		getOnClickNavigationProps: (row) => ({
			path: generatePathForPlatform(row.original),
			name: row.original.name,
		}),
		renderRowActionMenuItems: ({ row }) => (
			<>
				<Menu.Item component={Link} to={generatePathForPlatform(row.original)}>
					Open platform
				</Menu.Item>
				<CopySubmitLeadLinkItem platform={row.original} />
			</>
		),
		initialColumnVisibility: {
			createdBy: false,
			updatedAt: false,
		},
	});

	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<MantineReactTable table={table} />
		</div>
	);
}
