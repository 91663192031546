import {
	type CreatePlatformInput,
	type PlatformForm_PlatformFragment,
	PlatformStatus,
	type UpdatePlatformInput,
} from "@/__generated__/graphql";
import { useSavePlatformForm } from "@/pages/platforms/platform/platformForm/useSavePlatformForm";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { isPossiblePhoneNumber } from "react-phone-number-input";

type PlatformFormType = {
	name: string;
	contactName?: string;
	contactEmail?: string;
	contactPhoneNumber?: string;
	status: PlatformStatus;
	projectedRevenue?: number;
	projectedRevenueCurrency: string;
};

export function usePlatformForm(
	onClose: (forceClose?: boolean) => void,
	setDirty: (dirty: boolean) => void,
	platform?: PlatformForm_PlatformFragment | null,
) {
	const form = useForm<PlatformFormType>({
		mode: "controlled",
		initialValues: {
			name: platform?.name || "",
			contactName: platform?.contactName || "",
			contactEmail: platform?.contactEmail || "",
			contactPhoneNumber: platform?.contactPhoneNumber || "",
			status: platform?.status || PlatformStatus.NEW,
			projectedRevenue: platform?.projectedRevenue?.amount,
			projectedRevenueCurrency: platform?.projectedRevenue?.currency || "AUD",
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {
			name: isNotEmpty("Name is required"),
			contactPhoneNumber: (value) =>
				!value ? null : isPossiblePhoneNumber(value) ? null : "Must be a valid phone number",
			contactEmail: (value) => (!value ? null : isEmail("Must be a valid email")(value)),
		},
	});

	const { createPlatform, updatePlatform, loading } = useSavePlatformForm();

	const onSubmit = async (values: PlatformFormType) => {
		if (platform) {
			await submitUpdate(values);
		} else {
			await submitCreate(values);
		}
	};

	const submitUpdate = async (values: PlatformFormType) => {
		try {
			const input: UpdatePlatformInput = {
				platformId: platform?.id,
				name: values.name,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhoneNumber: values.contactPhoneNumber,
				status: values.status,
			};
			if (values.projectedRevenue) {
				input.projectedRevenue = {
					amount: values.projectedRevenue,
					currency: values.projectedRevenueCurrency,
				};
			}
			const { data } = await updatePlatform({
				variables: {
					input,
				},
			});
			if (data?.platform.update.success) {
				notifications.show({
					title: "Platform updated",
					message: "Platform successfully updated",
				});
				form.resetDirty();
				setDirty(false);
				onClose(true);
			} else {
				notifications.show({
					title: "Failed to create platform",
					message: `Failed with error: ${data?.platform.update.error?.code}`,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to update platform",
				message: "Something went wrong while updating the platform.",
			});
		}
	};

	const submitCreate = async (values: PlatformFormType) => {
		try {
			const input: CreatePlatformInput = {
				name: values.name,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhoneNumber: values.contactPhoneNumber,
			};
			if (values.projectedRevenue) {
				input.projectedRevenue = {
					amount: values.projectedRevenue,
					currency: values.projectedRevenueCurrency,
				};
			}
			const { data } = await createPlatform({
				variables: {
					input,
				},
			});
			if (data?.platform.create.success) {
				notifications.show({
					title: "Platform created",
					message: "Platform successfully submitted",
				});
				form.resetDirty();
				setDirty(false);
				onClose(true);
			} else {
				notifications.show({
					title: "Failed to create platform",
					message: `Failed with error: ${data?.platform.create.error?.code}`,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to create platform",
				message: "Something went wrong while creating the platform.",
			});
		}
	};

	return {
		form,
		onSubmit: form.onSubmit(onSubmit),
		loading,
	};
}
