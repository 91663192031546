import { type BusinessInformation_MerchantFragment, MerchantStatus } from "@/__generated__/graphql";
import { useSaveOnboardingData } from "@/pages/merchants/onboarding/useSaveOnboardingData";
import { paths } from "@/paths";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router";

type ProspectingForm = {
	legalEntityName: string;
	status: MerchantStatus;
	platformId?: string;
};

export function useBusinessInformationForm(
	setDirty: (dirty: boolean) => void,
	merchant?: BusinessInformation_MerchantFragment | null,
) {
	const form = useForm<ProspectingForm>({
		mode: "uncontrolled",
		initialValues: {
			legalEntityName: merchant?.legalEntityName || "",
			status: merchant?.status || MerchantStatus.LEAD_GENERATED,
			platformId: merchant?.platform?.id,
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {
			legalEntityName: isNotEmpty("Legal entity name is required"),
		},
	});
	const [submittingSave, setSubmittingSave] = useState(false);
	const [submittingContinue, setSubmittingContinue] = useState(false);
	const { saveOnboardingData, error } = useSaveOnboardingData(merchant?.id);
	const navigate = useNavigate();

	const onSubmit = async (proceed: boolean) => {
		if (form.validate().hasErrors) {
			return;
		}
		const values = form.getValues();
		try {
			const { data } = await saveOnboardingData({
				variables: {
					input: {
						merchantId: merchant?.id,
						legalEntityName: values.legalEntityName,
						newStatus: values.status,
						proceedToNextStage: proceed,
						platformId: values.platformId,
					},
				},
			});
			if (data?.merchant?.saveOnboardingData?.success) {
				notifications.show({
					title: "Prospecting data saved",
					message: "The provided prospecting data has been successfully saved.",
				});
				form.resetDirty();
				setDirty(false);
				navigate(
					generatePath(paths.assistedOnboarding, {
						merchantId: data?.merchant?.saveOnboardingData?.merchant?.id,
					}),
				);
			} else {
				notifications.show({
					title: `Failed to save prospecting data: ${data?.merchant?.saveOnboardingData?.error?.code}`,
					message: data?.merchant?.saveOnboardingData?.error?.message,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to save prospecting data",
				message: "Something went wrong while saving prospecting data.",
				color: "red",
			});
		}
	};
	const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingSave(true);
		await onSubmit(false);
		setSubmittingSave(false);
	};
	const onContinue = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingContinue(true);
		await onSubmit(true);
		setSubmittingContinue(false);
	};

	return {
		form,
		onSave,
		onContinue,
		submittingSave,
		submittingContinue,
		error,
	};
}
