import { gql, useFragment } from "@/__generated__";
import { AssistedOnboarding_QueryFragmentDoc, MerchantStage } from "@/__generated__/graphql";
import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { BusinessInformation } from "@/pages/merchants/onboarding/businessInformation/BusinessInformation";
import { OnboardingStep } from "@/pages/merchants/onboarding/common/OnboardingStep";
import { EmptyOnboardingStep } from "@/pages/merchants/onboarding/emptyStep/EmptyOnboardingStep";
import { Live } from "@/pages/merchants/onboarding/live/Live";
import { useAssistedOnboardingData } from "@/pages/merchants/onboarding/useAssistedOnboardingData";
import { paths } from "@/paths";
import { LoadingOverlay, Modal, Stepper } from "@mantine/core";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

gql(`
fragment AssistedOnboarding_Query on Query {
    merchant(merchantId: $merchantId) @include(if: $merchantIdProvided) {
        id
		stage
		...EmptyStage_Merchant
		...Live_Merchant
		...OnboardingStep_Merchant
    }
	...BusinessInformation_Query
}
`);

const STAGE_TO_STEP = {
	[MerchantStage.BUSINESS_INFORMATION]: 0,
	[MerchantStage.TRANSACTION_INFORMATION]: 1,
	[MerchantStage.SOLUTION_REQUIREMENTS]: 2,
	[MerchantStage.FEES]: 3,
	[MerchantStage.CONTRACTING]: 4,
	[MerchantStage.LIVE]: 5,
	[MerchantStage.CLOSED]: 6,
};

export function AssistedOnboarding() {
	const { merchantId } = useParams();
	const navigate = useNavigate();
	const onClose = () => navigate(generatePath(paths.merchants));
	const [isDirty, setDirty] = useState({ form: false, notes: false });
	const setDirtyForm = (dirty: boolean) => setDirty((prev) => ({ ...prev, form: dirty }));
	const setDirtyNotes = (dirty: boolean) => setDirty((prev) => ({ ...prev, notes: dirty }));
	const { protectedClose, confirm } = usePreventClosingDirtyForm(isDirty.form || isDirty.notes, onClose);
	const { data, loading } = useAssistedOnboardingData(merchantId);
	const queryData = useFragment(AssistedOnboarding_QueryFragmentDoc, data);
	const merchant = queryData?.merchant || undefined;
	const merchantStep = merchant ? STAGE_TO_STEP[merchant.stage] : 0;
	const [active, setActive] = useState(-1);
	const changeStep = (step: number) => {
		if (step <= merchantStep) {
			setActive(step);
		}
	};
	return (
		<Modal.Root opened onClose={protectedClose} fullScreen>
			<Modal.Overlay />
			<Modal.Content px={{ base: "xs", xs: "xl" }} pt="lg">
				{loading && <LoadingOverlay visible />}
				{!loading && (
					<Stepper
						active={active === -1 ? merchantStep : active}
						onStepClick={changeStep}
						size="sm"
						iconSize={25}
						styles={{ content: { paddingBlockStart: 0 } }}
					>
						<Stepper.Step label="Business information">
							<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
								<BusinessInformation onClose={protectedClose} setDirty={setDirtyForm} data={queryData} />
							</OnboardingStep>
						</Stepper.Step>
						<Stepper.Step label="Transaction information">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<EmptyOnboardingStep
										key="transaction-information"
										stage={MerchantStage.TRANSACTION_INFORMATION}
										onClose={protectedClose}
										setDirty={setDirtyForm}
										merchant={merchant}
										title="Transaction information"
									/>
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Solution requirements">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<EmptyOnboardingStep
										key="solution-requirements"
										stage={MerchantStage.SOLUTION_REQUIREMENTS}
										onClose={protectedClose}
										setDirty={setDirtyForm}
										merchant={merchant}
										title="Solution requirements"
									/>
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Fees">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<EmptyOnboardingStep
										key="fees"
										stage={MerchantStage.FEES}
										onClose={protectedClose}
										setDirty={setDirtyForm}
										merchant={merchant}
										title="Fees"
									/>
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Contracting">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<EmptyOnboardingStep
										key="contracting"
										stage={MerchantStage.CONTRACTING}
										onClose={protectedClose}
										setDirty={setDirtyForm}
										merchant={merchant}
										title="Contracting"
									/>
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Completed>
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<Live merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Completed>
					</Stepper>
				)}
			</Modal.Content>
			{confirm}
		</Modal.Root>
	);
}
