export const paths = {
	overview: "/",
	stages: "/stages",
	platforms: "/platforms",
	platform: "/platforms/edit/:platformId?",
	merchants: "/merchants",
	submitLead: "/submit-lead/:platformId",
	assistedOnboarding: "/merchants/onboarding/:merchantId?",
	reporting: "/reporting",
	escalations: "/escalations",
	accountSettings: "/account-settings",
	users: "/users",
	user: "/users/edit/:userId?",
};
