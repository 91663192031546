import type { EmptyStage_MerchantFragment, MerchantStatus } from "@/__generated__/graphql";
import { useSaveOnboardingData } from "@/pages/merchants/onboarding/useSaveOnboardingData";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

type ContractingForm = {
	status: MerchantStatus;
};

export function useEmptyStepForm(setDirty: (dirty: boolean) => void, merchant: EmptyStage_MerchantFragment) {
	const form = useForm<ContractingForm>({
		mode: "controlled",
		initialValues: {
			status: merchant.status,
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {},
	});
	const [submittingSave, setSubmittingSave] = useState(false);
	const [submittingContinue, setSubmittingContinue] = useState(false);
	const { saveOnboardingData, error } = useSaveOnboardingData(merchant.id);

	const onSubmit = async (proceed: boolean) => {
		if (form.validate().hasErrors) {
			return;
		}
		const values = form.getValues();
		try {
			const { data } = await saveOnboardingData({
				variables: {
					input: {
						merchantId: merchant.id,
						newStatus: values.status,
						proceedToNextStage: proceed,
					},
				},
			});
			if (data?.merchant?.saveOnboardingData?.success) {
				notifications.show({
					title: "Onboarding data saved",
					message: "The provided data has been successfully saved.",
				});
				form.resetDirty();
				setDirty(false);
			} else {
				notifications.show({
					title: `Failed to save data: ${data?.merchant?.saveOnboardingData?.error?.code}`,
					message: data?.merchant?.saveOnboardingData?.error?.message,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to save data",
				message: "Something went wrong while saving contracting data.",
				color: "red",
			});
		}
	};
	const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingSave(true);
		await onSubmit(false);
		setSubmittingSave(false);
	};
	const onContinue = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingContinue(true);
		await onSubmit(true);
		setSubmittingContinue(false);
	};

	return {
		form,
		onSave,
		onContinue,
		submittingSave,
		submittingContinue,
		error,
	};
}
